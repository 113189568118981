.divText {
  display: flex;
  background: rgb(244, 246, 250);
  max-width: 150px;
}
.divDirection {
  direction: ltr;
}
.divUnderText {
  display: block;
  padding: 0.5rem 0.75rem;
  margin: auto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: rgb(115, 120, 130);
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
