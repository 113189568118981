/* .tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; 
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  position: absolute;
  z-index: 1;
} */

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltip-text {
  visibility: visible;
}

.tooltip-text {
  visibility: hidden;
}
