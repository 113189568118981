/* .NormalAttachment__fileViewer {
  border: 1px solid #E5E5E5;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  background: #FFFFFF;
} */

.NormalAttachment__uploadedFileDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.NormalAttachment__uploadedFileDetails_text {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-right: 40px;
  width: 115px;
  overflow: hidden;
  white-space: nowrap;
}

.NormalAttachment__fileName {
  font-size: '14px';
  overflow: hidden;
}

.Attachment__fileDate {
  font-size: 12px; 
  color: #7A7A7A;
}