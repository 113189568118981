.manage_client {
  margin-left: 80px;

  .top_client_header {
    display: flex;
    justify-content: space-between;
    .right_filter_section {
      display: flex;
      .reset_filter {
        margin: auto 15px;
        display: flex;
        a {
          margin: auto 5px;
          text-decoration: none;
        }
      }
    }
  }
}

.ManageClient__filtersSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 6px;
}

.ManageClient__topSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
  margin: 3px 3px 8px 3px;
}

.ManageClient__searchFiltersContainer {
  display: flex;
  align-items: center;
}

.ManageClient__filterChipsContainer {
  padding-left: 6px;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 16px;
}
.Btn__group {
  font-size: 9rem;
}
.client-filter {
  border-bottom: 2px solid blue;
}

a {
  text-decoration: none;
}