.InovuaReactDataGrid--theme-default-ligh {
  font-size: 20px !important;
}
.InovuaReactDataGrid {
  /* color: brown !important; */
  /* font-size: larger !important; */
  font-family: 'Noto Sans' !important ;
  max-height: '100vh' !important;
}
.InovuaReactDataGrid__header {
  font-family: 'Noto Sans' !important ;
  padding: 8px 8px 8px 0px !important;
  background-color: #e1e1e5 !important;
}
.MuiDataGrid-virtualScroller {
  background-color: white !important;
}
.MuiDataGrid-columnHeaders {
  background-color: #e1e1e5 !important;
}

/* .MuiDataGrid-virtualScroller .css-1w5m2wr-MuiDataGrid-virtualScroller {
  background-color: white !important;
} */
