body {
  margin: 0;
}

.cognisaas-pointer {
  cursor: pointer;
}

svg#filter path:hover {
  /* fill: orange !important; */
  stroke: #2d60f6 !important;
}

.active-btn {
  background: rgba(45, 96, 246, 0.15) !important;
  /* background-color: red !important; */
}

.cognisaas-red-card {
  border-left: 4px solid #e75b5c !important;
}

.cognisaas-amber-card {
  border-left: 4px solid #efa958 !important;
}

.cognisaas-green-card {
  border-left: 4px solid #4f9f52 !important;
}

.cognisaas-blue-card {
  border-left: 4px solid #4390e1 !important;
}

.cognisaas-grey-card {
  border-left: 4px solid #7a7a7a !important;
}

.label-text {
  color: #7a7a7a !important;
}

.link-text {
  color: #2d60f6 !important;
}

.cogni-red {
  color: #e75b5c;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  /* background-color: #f5f5f5 !important; */
}

::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  /* background-color: #f5f5f5 !important; */
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c0c0c0 !important;
}

.green-status-indicator {
  background-color: #4f9f52;
  display: 'inline-block';
  width: '8px';
  height: '8px';
  border-radius: '5px';
  margin-right: '8px';
}

.red-status-indicator {
  background-color: #e75b5c;
  display: 'inline-block';
  width: '8px';
  height: '8px';
  border-radius: '5px';
  margin-right: '8px';
}

.amber-status-indicator {
  background-color: #efa958;
  display: 'inline-block';
  width: '8px';
  height: '8px';
  border-radius: '5px';
  margin-right: '8px';
}

.blue-status-indicator {
  background-color: #4390e1;
  display: 'inline-block';
  width: '8px';
  height: '8px';
  border-radius: 5px;
  margin-right: '8px';
}

.grey_status_box_task {
  width: 12px;
  height: 12px;
  float: left;
  border-radius: 7px;
  margin-top: 3px !important;
  background: #7a7a7a;
}

.green_status_box_task {
  width: 12px;
  height: 12px;
  float: left;
  border-radius: 7px;
  margin-top: 3px !important;
  background: #4f9f52;
}

.amber_status_box_task {
  width: 12px;
  height: 12px;
  float: left;
  border-radius: 7px;
  margin-top: 3px !important;
  background: #efa958;
}

.red_status_box_task {
  width: 12px;
  height: 12px;
  float: left;
  border-radius: 7px;
  margin-top: 3px !important;
  background: #e75b5c;
}

.blue_status_box_task {
  width: 12px;
  height: 12px;
  float: left;
  border-radius: 7px;
  margin-top: 3px !important;
  background: #4390e1;
}

.grey_status_box_task {
  width: 12px;
  height: 12px;
  float: left;
  border-radius: 7px;
  margin-top: 3px !important;
  background: #7a7a7a;
}

a {
  text-decoration: none;
}

a:visited {
  color: #093bcd;
}

a:active {
  color: #093bcd;
}

/* a:hover {
  color: #093BCD
} */
/* * {
  box-sizing: border-box;
} */

.table-test-color {
  background-color: '#d47483' !important;
}
