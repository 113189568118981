.info {
    color:#4bb1cf;
}

.danger {
    color:#ED4F32;
}

.primary {
    color:#288feb;
}

.success {
    color:#15CD72;
}

.warning {
    color:#EDE04D;
}


.blinker {
    color: #e50d0d;
    animation-duration: 1s;
    animation-name: warningBlinker;
    animation-iteration-count: infinite;
    margin-left: 5px;
  }
  
@keyframes warningBlinker {
0% {
    opacity: 1;
}

20% {
    opacity: 0.7;
}

50% {
    opacity: 0;
}

80% {
    opacity: 0.7;
}

95% {
    opacity: 0.95;
}

100% {
    opacity: 1;
}
}
