.ProductRequirement__container {
  padding: 18px;
}

.ProductRequirement__headerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.ProductRequirement__body {
  display: flex;
  min-height: 75vh;
  flex-direction: row;
  align-items: stretch;
  /* flex-wrap: wrap; */
}

.ProductRequirement__productView {
  min-width: 270px;
  background: #ffffff;
  border-radius: 4px;
  margin-right: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 68vh;
}

.ProduxtRequirement__productView_body {
  display: flex;
  flex-direction: column;
}

.ProductRequirement__productView_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.ProductRequirement__productView_listContainer {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  max-height: 330px;
  overflow: scroll;
  padding: 10px;
}

/* .ProductRequirement__productView_listCardCore {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: left;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 12px;
    cursor: pointer;
    box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.05);
    position: relative;
}

.ProductRequirement__productView_listCardCoreInactive {
    border: 1px solid #E5E5E5;
}

.ProductRequirement__productView_listCardActive {
    border: 1px solid #2D60F6;
} */

.ProductRequirement__reqViewWrapper {
  background: #ffffff;
  border-radius: 4px;
  flex-grow: 1;
  padding: 20px;
  height: 68vh;
  min-width: 300px;
}

.ProductRequirement__reqView_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 18px;
}

.ProductRequirement__reqView_cardContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 90%;
  overflow: scroll;
  /* justify-content: center; */
}

/* requirement drawer styles */

.ProductRequirement__reqDrawer_container {
  padding: 70px 20px 20px 20px;
}

.ProductRequirement__noProductFoundContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  height: 70vh;
}

.ProductRequirement__noRequirementsFoundContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
