.ProductRequirement__productView_listCardCore {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: left;
  /* background: RGB(255, 255, 255); */
  border-radius: 4px;
  padding: 12px;
  /* margin-top: 12px; */
  cursor: pointer;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.05);
  position: relative;
  border-radius: '4px 4px 0px 0px';
}

/* .ProductRequirement__productView_listCardCoreInactive {
  /* border: 1px solid #e5e5e5; */
/* } 
*/

.ProductRequirement__productView_listCardActive {
  border: 1px solid #e5e5e5;
}
