.react-datepicker__input-container input {
    width: 158px;
    padding: 12px;
    background:url('../../../assets/svgs/calender.svg') no-repeat scroll 125px;
    background-color: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
}

.react-datepicker__input-container input:focus {
    outline: none;
}

.datepicker__select {
    padding: 8px;
    border: 1px solid lightgray;
    border-radius: 2px;
    background-color: white;

}

.datepicker__option {
    padding: 5px;
}