* {
    font-family: 'Noto Sans';
}

.gantt_project, .gantt_usecase {
    width: 100%;
    height: 100vh;
    margin-top: 10px;
}

.gantt_task_row,
.gantt_task_row.odd,
.gantt_data_area,
.gantt_cal_light,
.gantt_cal_larea div {
    background: #fff;
}

.gantt_cal_ltext textarea {
    background: #ececec;
}

.gantt_cal_light .gantt_cal_ltitle {
    padding-bottom: 0px;
}

.weekend {
    background: #ffb9f1;
}

.gantt_task_row.odd.gantt_selected {
    background-color: #fff3a1;
}

.resource_marker {
    text-align: center;
}

.resource_marker div {
    width: 28px;
    height: 28px;
    line-height: 29px;
    display: inline-block;
    border-radius: 15px;
    color: #FFF;
    margin: 3px;
}

.resource_marker.workday_green div {
    background: #4F9F52;
}

.resource_marker.workday_amber div {
    background: #EFA958;
}

.resource_marker.workday_red div {
    background: #E75B5C;
}

.gantt_container,
.gantt_tooltip {
    background-color: #fff;
    /* // font-family: Inter !important; */
}

.gantt_grid_data .gantt_row.gantt_selected,
.gantt_grid_data .gantt_row.odd.gantt_selected,
.gantt_task_row.gantt_selected,
.gantt_task_row.gantt_selected .gantt_task_cell {
    background-color: transparent !important;
    border-right-color: #ebebeb !important;
}

.gantt_row .gantt_row_task {
    cursor: pointer;
}

.gantt_row .gantt_row_project {
    cursor: pointer;
}

.baseline {
    position: absolute;
    border-radius: 20px;
    opacity: 0.6;
    height: 12px;
    background: #7A7A7A;
    border: 1px solid rgb(114, 110, 104);
}

.baseline-milestone {
    position: absolute;
    opacity: 0.6;
    height: 12px;
    width: 12px;
    background: #7A7A7A;
    border: 1px solid rgb(114, 110, 104);
    transform: rotate(45deg);
}

/* Move task line upper */
.gantt_task_line,
.gantt_line_wrapper {
    margin-top: -12px;
    border-radius: 20px;
}

.gantt_task_line {
    border-radius: 20px !important;
}

.gantt_task_line.gantt_milestone {
    border-radius: 0px !important;
}

.gantt_side_content {
    margin-bottom: 7px;
}

.gantt_task_link .gantt_link_arrow {
    margin-top: -15px
}

.gantt_side_content.gantt_right {
    bottom: 0;
}

.gantt_critical_link .gantt_line_wrapper,
.gantt_critical_link .gantt_link_arrow,
.gantt_link_control {
    z-index: 1;
}

.without-pointer-events {
    pointer-events: none;
    cursor: none;
}

.with-pointer-events {
    pointer-events: all;
    cursor: pointer;
}

.with-default-pointer-events {
    pointer-events: all;
    cursor: default;
}

.weekend {
    background: #e7f3fd;
}

[aria-label="Sat"], [aria-label="Sun"] {
    background: #e7f3fd;
}