.reports_seconda-nav {
  margin-left: 70px !important;
}

.reports_navgrid {
  height: 100vh;
  border-right: 1px solid gray;
  border-color: rgba(0, 0, 0, 0.12);
}

.reports_reportCard {
  background: #ffffff;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-top: 20px;
  height: 100%;
}

.reports_reportName {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
}

.reports_detailsHeader {
  display: flex;
}

.reports_detailsHeaderSpan {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.reports_group {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 40px auto 40px;
}

.reports_visualiseIcon {
  cursor: pointer;
  margin-right: 10px;
}