.AddNewProduct__container {
  padding: 20px; 
}
.AddNewProduct__bodyForm {
  background: #FFFFFF;
  border-radius: 4px;
  padding: 20px;
}

.AddNewProject__formFieldContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.AddNewProject__formSingleField {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: left;

}

.AddNewProduct__formActionButtonsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1 0 calc(25% - 10px);
  justify-content: flex-end;
  margin-top: 30px;
}