.AddNewRequirement__bigWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
}
.AddNewRequirement__container {
  width: 600px;
}

.AddNewRequirement__bodyForm {
  display: flex;
  flex-direction: column;
  align-items: left;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 20px;
}

.AddNewRequirement__formSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.AddNewRequirement__formSingleField {
  margin-right: 20px;
  margin-bottom: 20px;
}

.AddNewRequirement__attachmentHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.AddNewRequirement__formActionButtonsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1 0 calc(25% - 10px);
  justify-content: flex-end;
  margin-top: 30px;
}

.AddNewRequirement__oneAttachmentWrapper {
  background: #FFFFFF;
/* Greys/500 */

border: 1px solid #E5E5E5;
box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.05);
border-radius: 4px;
width: 330px;
margin-bottom: 10px;

}