// brand colors
$BRAND_COLOR__PURPLE: #3D38BA;
$BRAND_COLOR__ORANGE: #FFA100;

// grays 
$GRAYS__BLACK: #000000;
$GRAYS__DARK_GRAY: #7A7A7A;
$GRAYS__LIGHT_GRAY: #E5E5E5;
$GRAYS__LIGHTER_GRAY: #F3F3F3;
$GRAYS__WHITE: #FFFFFF;

// brags
$BRAGS__BLUE:#4390E1;
$BRAGS__CORAL:#E75B5C;
$BRAGS__ORANGE:#EFA958;
$BRAGS__GREEN:#4F9F52;
$BRAGS__LITE_BLUE:#9BC5F2;
$BRAGS__LITE_CORAL:#F1AFAF;
$BRAGS__LITE_ORANGE:#F9D1A2;
$BRAGS__LITE_GREEN:#BDF2BF;
$BRAGS__DARK_BLUE:#2D60F6;

// profile pastels
$PROFILE_PASTELS__PALE_RED :#F9CFCF;
$PROFILE_PASTELS__PEACH_UMBRELLA :#F9E8CF;
$PROFILE_PASTELS__SNOW_FLURRY :#E4F9CF;
$PROFILE_PASTELS__BLANCHED_ALMOND :#CFF6F9;
$PROFILE_PASTELS__PALE_BLUE :#CFE5F9;
$PROFILE_PASTELS__PALE_VIOLET :#D0CFF9;
$PROFILE_PASTELS__PALE_PINK:#F6CFF9;