.MuiDataGrid-groupingCriteriaCell span {
  font-weight: 600;
  font-size: 18px;
}

.sticky-div {
  /* background-color: green !important; */
  position: sticky !important;
  z-index: 1200;
  top: 64px !important;
  /* padding: 3px 0px; */
}
