@import '../../../utils/designSystemColors.scss';

.OnBoardNewAccount__mainContainer {
    margin: 2.5vh 7.7vw 0px 7.4vw;
}

.OnBoardNewAccount__mainContainer__mobileScreen {
    margin: 10px 20px 0px 20px;
}

.OnBoardNewAccount__Stepper__stepIconCompleted {
    color: $BRAGS__GREEN;
    padding: 4px 7px;
    background: $GRAYS__WHITE;
    border: 1px solid $BRAGS__GREEN;
    box-sizing: border-box;
    border-radius: 50%;
}

.OnBoardNewAccount__Stepper__stepIconActive {
    color: $BRAGS__DARK_BLUE;
    padding: 4px 7px;
    background: $GRAYS__WHITE;
    border: 1px dashed $BRAGS__DARK_BLUE;
    box-sizing: border-box;
    border-radius: 50%;
}

.OnBoardNewAccount__Stepper__stepIconNormal {
    color:$GRAYS__DARK_GRAY;
    border: 1px dashed $GRAYS__DARK_GRAY;
    box-sizing: border-box;
    border-radius: 50%;
    padding: 4px 7px;
}

.OnBoardNewAccount__Stepper__optionalText {
    color: $GRAYS__DARK_GRAY;
    font-size: 12px;
}

.OnBoardNewAccount__Stepper__eachStep {
    padding-left: 0px;
}

.OnboardAccountProfile__container {
    background-color: $GRAYS__WHITE;
    margin-top: 24px;
    padding: 20px;
}
.OnboardAccount__section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 0 calc(25% - 10px);
    margin: 12px 0px;
}

.OnboardAccountProfile__formField {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    margin-bottom: 5px;
}

.OnboardAccountProfile__formField > label {
    font-size: 12px;
    margin-bottom: 4px;
}

.OnboardAccountProfile__formSelectControl {
    min-width: 250px;
}

.OnboardAccountProfile__Headers {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.OnboardAccountProfile__formActionButtonsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 0 calc(25% - 10px);
    justify-content: flex-end;
    margin-top: 30px;
}

.OnboardAccountProfile__formAlternateTopSection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // flex: 1 0 calc(25% - 10px);
    margin: 12px 0px;
    justify-content: space-between;
    align-items: center;
}

.OnboardAccount__customFieldWrapper {
    display: flex;
    flex-direction: row;
}

.OnboardAccount__customFieldName {
    width: 30vw;
    // background: green;
}

.OnboardAccount__formBottomBarWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
}

.OnboardAccount__customFieldInput {
    display: flex;
    flex-direction: row;
    justify-content: left;
    
}