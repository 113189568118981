.MainLayout__wrapper {
  padding-top: 65px;
  padding-left: 100px;
}

.MainLayout__wrapperSmallScreen {
  padding-top: 65px;
  padding-left: 0px;
}

.MainLayout__wrapperSmallHeightSceens {
  padding-top: 64px;
  padding-left: 80px;
  padding-right: 8px;
}
