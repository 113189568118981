.componentWrapper {
  cursor: pointer;
  outline: none;
}

.componentBackground {
  user-select: none;
}

.componentTop {
  user-select: none;
}

.barLabel {
  fill: #000;
  text-anchor: middle;
  font-weight: lighter;
  dominant-baseline: central;
  -webkit-touch-callout: none;
  -webkit-component-select: none;
  -moz-component-select: none;
  -ms-component-select: none;
  user-select: none;
  pointer-events: none;
  font-size: small;
}

.barLabelOutside {
  fill: #000;
  text-anchor: start;
  -webkit-touch-callout: none;
  -webkit-component-select: none;
  -moz-component-select: none;
  -ms-component-select: none;
  user-select: none;
  pointer-events: none;
  font-size: small;
}
