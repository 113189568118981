.FilterChip__wrapper {
    display: inline-flex;
    flex-direction: row;
    margin-left: 6px;
}

#FilterChip__dropdownHeader {
    background: #cfcfcf;
    padding: 0 12px;
    display: flex;
    flex-direction: row;
    border-radius: 16px;
}

#FilterChip__lockButton {
    padding: 0px;
}

.FilterChip__selectedOption {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 4px;
    width: max-content;
}