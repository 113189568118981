.LabeledBadge__container {
  display: flex;
  align-items: center;
  padding-bottom: 3px;
}

.LabeledBadge__containerWithDefaultBorder {
  display: flex;
  align-items: center;
  padding-bottom: 3px;
  border-bottom: 2px solid #e5e5e5;
}

.LabeledBadge__containerActiveBorder {
  border-bottom: 2px solid #2d60f6;
}

.Badge_label_font {
  font-size: 0.9rem !important;
  color: #000000;
}

.Badge_label_font_active {
  color: #093bcd;
}

.LabeledBadge__activeDefault {
  color: '#2D60F6';
}
