.manage_client {
  margin-left: 80px;
}

.manage_client .top_client_header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.manage_client .top_client_header .right_filter_section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.manage_client .top_client_header .right_filter_section .reset_filter {
  margin: auto 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.manage_client .top_client_header .right_filter_section .reset_filter a {
  margin: auto 5px;
  text-decoration: none;
}

.ManageClient__filtersSection {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 6px;
}

.ManageClient__topSection {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px 0px;
  margin: 3px 3px 8px 3px;
}

.ManageClient__searchFiltersContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ManageClient__filterChipsContainer {
  padding-left: 6px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 16px;
}

.Btn__group {
  font-size: 9rem;
}

.client-filter {
  border-bottom: 2px solid blue;
}

a {
  text-decoration: none;
}

.MuiDataGrid-pinnedColumnHeaders {
  background-color: #e1e1e5 !important;
}
*, *:focus {
  outline: none;
}


select:focus {
  outline: none;
}
