.RequirementDetailsTab__container {
  padding-top: 24px;
  position: relative;
}

.RequirementDetailsTab__metadataContainer {
  margin: 24px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.RequirementDetailsTab__metadataSection {
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.RequirementDetailsTab__clientSection_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 12px;
}

.RequirementDetailsTab__clientViewCard_container {
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 20px;
}

.RequirementDetailsTab__clientViewCard_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 12px;
}

.RequirementDetailsTab__clientViewCard_headerLeftSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}

.RequirementDetailsTab__clientViewCard_usecaseContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.RequirementAttachmentTab__noRecordsContainer {
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.RequirementAttachmentTab__noRecordsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.RequirementDetailsTab__editOptions_container {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 20px;
}