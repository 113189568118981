.LabeledBadge__badge {
  height: 22px;
  width: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
  border-radius: 30px;
  background: #e3e3e3;
  padding: 0px 5px;
  color: #7a7a7a;
}
.LabeledBadge__badge__large {
  height: 22px;
  width: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
  border-radius: 30px;
  background: #e3e3e3;
  padding: 0px 5px;
  color: #7a7a7a;
  font-size: 0.775rem;
}

.LabeledBadge__badgeActive {
  background-color: #2d60f6;
}
