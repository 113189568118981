.Attachment__container {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Attachment__uploadedFileDetails {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.Attachment__uploadedFileDetails_text {
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin-right: 40px;
    width: 115px;
    overflow: hidden;
    white-space: nowrap;
}

.Attachment__fileName {
    font-size: '14px';
    overflow: hidden;
}

.Attachment__fileDate {
    font-size: 12px; 
    color: #7A7A7A;

}

.Attachment__fileViewer {
    border: 1px solid #E5E5E5;
    box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    background: #FFFFFF;
}

.Attachment__bulkUploadContainer {
    padding: 0;
}

.Attachment__dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 310px;
    height: 132px;
    background: #FFFFFF;
    border: 1px dashed #E5E5E5;
    border-radius: 4px;
    margin-top: 12px;
    margin-bottom: 24px;
}

.Attachment__actionButtonsForDroppedFiles {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
}