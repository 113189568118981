div.NOTIFICATION{
    font-family: 'Noto Sans';
    div.history-tl{
      margin:auto !important;
      display:block!important;
      position:relative!important;
    }
    div.history-tl ul.tl{
        margin-top:30px!important;
        padding:0!important;
        display:inline-block!important;
    
    }
    div.history-tl ul.tl div div li{
        list-style: none!important;
        margin:auto!important;
        margin-left: 30px!important;
        min-height:50px!important;
        border-left: 1px solid #3C38B2 !important;
        padding:15px 0 50px 30px!important;
        position:relative!important;
    }
    div.history-tl > ul.tl > div::before{
        position: absolute!important;
        left: 21px !important;
        content: " "!important;
        border-radius: 500%!important;
        background:#3C38B2!important;
        height: 20px!important;
        width: 20px!important;
        transition: all 500ms ease-in-out!important;
    
    }
    div.history-tl ul.tl div div li:hover::before{
        border-color: #3C38B2 !important;
    }
  
    ul.tl div div li div.timestamp_date{
        padding-bottom: 15px !important;
        
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #546e7a;
    }
    .date{
      margin: 5px 0px 5px !important;
    }
  }
.item-title{
    font-size: 14px;
  }
  .userNameHeading{
    font-weight: 600;
  }