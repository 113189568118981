.gridRow {
  fill: #fff;
}

.gridRow:hover {
  fill: blue;
}

.gridRowLine {
  stroke: grey;
  stroke-width: 1;
}

.gridTick {
  stroke: grey;
}

.todayRect {
  fill: rgb(245, 242, 186);
  pointer-events: all;
}

.todayRect:hover {
  fill: rgb(213, 242, 179);
}

.gridCell {
  fill: white
}

.gridCell:hover {
  fill: paleturquoise
}